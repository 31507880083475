import React, { useEffect } from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import ParallaxBanner from "../components/parallaxBanner"
import KeyInformation from '../components/keyInformation'
import ImageGallery from '../components/imageGallery'
import NextPage from "../components/featuredProject" 
import TextBanner from '../components/textBanner'
import Header from '../components/header'
import Seo from '../components/seo'

const Project = ({data}) =>{
    const {title} = data.project
    useEffect(() => {
       document.querySelector('.initial--zoom').classList.add('in-view');
    },);
    var next = data.nextProject;
    return(
        <>
        <Seo title={title}/>
        <Layout>

            <Header />
            
            <ParallaxBanner image={data.project.image} title={data.project.title} /> 
     
            <TextBanner title={data.project.textTitle} content={data.project.textContent} author={data.project.textSubtitle}  />

            <ImageGallery data={data} />

            <KeyInformation titleOne={data.project.titleOne} copyOne={data.project.copyLineOne} copyTwo={data.project.copyLineTwo} copyThree={data.project.copyLineThree} titleTwo={data.project.titleTwo} titleThree={data.project.titleThree} />

            <NextPage caption='Next Project' image={next.image} title={next.title}  slug={next.slug} /> 

        </Layout>
        </>
    )
} 



export const query = graphql`
query project ($id: String!, $nextPageId : String!){
    project : datoCmsProject(id: {eq: $id}){
        title
        titleOne 
        titleTwo
        titleThree
        copyLineOne
        copyLineTwo
        copyLineThree
        textTitle 
        textContent
        textSubtitle
        slug
        imageGallery{
            url
        }
        image {
            gatsbyImageData(placeholder: NONE)
        }
    }
    nextProject : datoCmsProject(id: {eq: $nextPageId}){
        title
        slug
        image {
            gatsbyImageData(placeholder: NONE)
        }
    }
    allDatoCmsOurStory {
        nodes {
          heroTitle
          heroImage {
            gatsbyImageData
          }
        }
      }
    allDatoCmsExpertise {
        nodes {
          heroTitle
          heroImage {
            gatsbyImageData
          }
        }
    }
}
`
export default Project