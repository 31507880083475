import React, { useMemo, useState } from "react"
import AnimateInView from "../components/animation/animateInView"
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

function animation(inView, intersectionObserver) {
  if (intersectionObserver?.target) {
    if (inView) {
      intersectionObserver.target.classList.add('in-view')
    } else {
      intersectionObserver.target.classList.remove('in-view')
    }
  }
}


function useIndex (end) {
  const [index,setIndex] = useState(1)

  const formatIndex = useMemo(() =>{
    return `${Math.ceil((index * (end - 1)) + 1  )}/${end}`
  },[index,end])

  return [formatIndex,setIndex]
}


const ImageGallery = ({ data }) => {
  const {
    project: {
      imageGallery
    }
  } = data

  const [index,setIndex] = useIndex(imageGallery.length)

  const swiperOptions = useMemo(() => ({
    speed: 750,
    grabCursor: true,
    resistanceRatio: 0,
    resistance: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    watchSlidesProgress: true,
    modules: [Navigation, Pagination],

    onProgress: function () {

      var swiper = this;
      setIndex(swiper.progress)
      for (var i = 0; i < swiper.slides.length; i++) {
        var slideProgress = swiper.slides[i].progress;
        var innerOffset = swiper.width * 0.5;
        var innerTranslate = slideProgress * innerOffset;
        swiper.slides[i].querySelector(".parrallax--slide--inner").style.transform = "translate3d(" + innerTranslate + "px, 0, 0)";
      }

    },
    onSetTranslate: function () {
      var swiper = this;
      for (var i = 0; i < swiper.slides.length; i++) {
        swiper.slides[i].querySelector(".parrallax--slide--inner").style.transition = `0ms ease all`;
      }
    },
    onTransitionStart: function (speed) {

      var swiper = this;
      for (var i = 0; i < swiper.slides.length; i++) {
        swiper.slides[i].style.transition = speed + "ms";
        swiper.slides[i].querySelector(".parrallax--slide--inner").style.transition = `750ms ease all`;
      }
    },
  }), [setIndex])





  return (
    <div className="image--gallery max-900 ma p20 ma">
      <div className="ratio-2-1 overflow-hidden mb40">
        <AnimateInView animation={animation} className="will-zoom bg-image">
          <Swiper  {...swiperOptions} className="bg-image "   >
            {imageGallery.map((image, index) => (
              <SwiperSlide className="parrallax--slide" key={index} >
                <div style={{ backgroundImage: `url(${image.url})` }} className='parrallax--slide--inner'></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </AnimateInView>

      </div>
      <div className="w-100 flex justify-between">

        <div className="swiper-nav swiper-prev">
          <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 11.6468L2.35319 7L7 2.35319L7 0L3.0598e-07 7L7 14L7 11.6468Z" fill="#616161" />
          </svg>
        </div>
        <div className="flex flex-column justify-center">
          {index}
        </div>

        <div className="swiper-nav swiper-next">
          <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1.02861e-07 11.6468L4.64681 7L-5.09098e-07 2.35319L-6.11959e-07 0L7 7L0 14L-1.02861e-07 11.6468Z" fill="#616161" />
          </svg>
        </div>
      </div>
    </div>

  )
}
export default ImageGallery
