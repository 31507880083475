import React, { useMemo } from 'react'
import ConditionallyRender from '../components/conditionallyRender'
export default function FormatStats({ stat, className }) {

    const formatStat = useMemo(() => {
        const splitStat = stat.split('m2')

        return splitStat.map((sub, index) => {
            return <p className={className}>
                {sub}
                <ConditionallyRender when={(index !== splitStat.length - 1)}>
                    m<sup>2</sup>
                </ConditionallyRender>
            </p>

        })

    }, [stat, className])


    return (
        <>
            {formatStat}
        </>
    )
}
