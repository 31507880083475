import * as React from "react"
import Spacer from "../components/spacer"
import FormatStats from "../utils/formatStats"
const keyInformation = ({titleOne, titleTwo, titleThree, copyOne, copyTwo, copyThree,spacerTop = true}) => (
  <div className="key--information p20 text-center m-pt0">
   { spacerTop ? <Spacer /> : null}
    <div className="grid grid-3 m-grid-1 m-flex-column m-pt0"> 
      <div className="col p20 m-pt0  ">
        <p className="m0">{titleOne}</p>
        <FormatStats stat={copyOne} className="m0 h3 mt20"/>
      </div>
      <div className="col p20">
        <p className="m0">{titleTwo}</p>
        <FormatStats stat={copyTwo} className="m0 h3 mt20"/>
      </div>
      <div className="col p20">
        <p className="m0">{titleThree}</p>
        <FormatStats stat={copyThree} className="m0 h3 mt20"/>
      </div>
    </div>
    <Spacer />    
  </div> 
) 
export default keyInformation
